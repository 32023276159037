@import "@angular/material/prebuilt-themes/indigo-pink.css";
/*@import '~bootstrap/dist/css/bootstrap.min.css';*/

@font-face {
  font-family: Stolzl-Regular;
  src: url('assets/fonts/Stolzl-Regular.ttf');
}
@font-face {
  font-family: Roboto-Bold;
  src: url('assets/fonts/Roboto-Bold.ttf');
}
@font-face {
  font-family: Roboto-Regular;
  src: url('assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: Stolzl-Medium;
  src: url('assets/fonts/Stolzl-Medium.ttf');
}
@font-face {
  font-family: Stolzl-Bold;
  src: url('assets/fonts/Stolzl-Bold.ttf');
}
@font-face {
  font-family: Stolzl-Book;
  src: url('assets/fonts/Stolzl-Book.ttf');
}
@font-face {
  font-family: Stolzl-Thin;
  src: url('assets/fonts/Stolzl-Thin.ttf');
}
@font-face {
  font-family: Stolzl-Light;
  src: url('assets/fonts/Stolzl-Light.ttf');
}
.app_font {
  font-family: "Montserrat", sans-serif;
}


.mat-dialog-container {
  padding: 0px 0px 25px 0px !important;
  overflow-x: hidden !important;
  /*border-radius: 10px !important;*/
}

.cdk-global-scrollblock {
  overflow: hidden;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
  background: #FFFFFF !important;
}

/*.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.88) !important;
}*/
.invalid {
  border: 1px solid #a94442;
}

.validation-err-msg {
  color: red;
  font-size: 11px;
  font-family: Stolzl-Regular;
  position: absolute;
}

button:focus,
input:focus,
textarea:focus {
  outline: none !important;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f2f7ff !important;
}

.table-striped > tbody > tr {
  border-bottom: 1px solid #bed1eb !important;
}

.required-icon {
  color: red;
}

.error {
  font-family: Stolzl-Regular;
  font-size: 15px;
  color: #ff5b3b;
  text-align: center;
}

html {
  overflow: hidden;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}



/* media queries */
@media screen and (max-width: 767px) {
  .containerx {
    width: 90%;
  }

  .side-nav-menu {
    max-width: 100% !important;
    margin: 0px;
    text-align: center;
  }

  .sidenave-items {
    margin: 0px;
    margin-top: 0% !important;
  }

  .cust_table thead {
    table-layout: unset !important;
  }

  .cust_table {
    table-layout: fixed;
  }

  .cust_table tbody tr {
    table-layout: unset !important;
  }

  .cust_table tbody th,
  .cust_table tbody td {
  }

  .analytics_row {
    padding: 0px 10px;
    text-align: center;
  }

  .mat-card {
    text-align: center;
  }

  .generate {
    display: none;
  }

  .generate_btn {
    width: 100% !important;
  }

  .generate_btn .button {
    width: 100%;
  }

  .trans_label {
    padding: 0px;
    text-align: left;
  }

  .my-sidenav-content {
    margin-left: 0% !important;
  }

  .submenu-container {
    text-align: center;
    padding-left: 0px;
  }

  .submenu-li .submenu,
  .submenu-li .link-active {
    margin-left: 0px !important;
  }

  .submenu-container li {
    width: 100%;
  }

  .container,
  .containerx,
  .add_card_container {
    width: 100% !important;
    padding: 10px 0px;
    height: auto !important;
  }

  .container-card {
    min-height: auto !important;
  }

  .left-heading {
    text-align: left;
  }

  .container-card .mt-c-6 {
    margin-top: 20px !important;
    position: relative;
    top: -35px;
  }

  .button {
    padding: 8px !important;
  }

  .container-fluid {
    padding: 0px;
  }

  .row {
    margin: 0px;
  }

  .main_container {
    padding: 0px;
  }

  .example-container {
    width: 100% !important;
  }

  .main_container {
    padding: 0px !important;
  }

  .right_content_block {
    width: 100% !important;
  }

  /* .side-block {
    width: 100% !important;
  } */

  .from_text {
    text-align: center !important;
    margin-bottom: 15px;
  }

  .ip_addr {
    margin-bottom: 20px;
  }

  .end_text {
    width: 100%;
    margin-bottom: 15px;
  }

  .pay_title {
    font-size: 14px;
  }

  .mob_header li:nth-child(2),
  .mob_header li:nth-child(3) {
    padding-left: 0px;
  }

  .mob_no_padding {
    padding: 0px !important;
  }

  .mob_no_padding .payment-header-right {
    line-height: 30px;
  }

  .no-padding {
    padding: 0px;
  }

  .mob_mar_bot {
    margin-bottom: 15px;
  }

  .date-label {
    max-width: 100% !important;
  }

  .bs-datepicker-container {
    left: 9% !important;
  }
}

@media screen and (min-width: 766px) {
  .my-sidenav-content {
    margin-left: 16.66666667% !important;
  }

  .mt-c-6 {
    margin-top: 4rem !important;
  }
}

.typeahead_formcontainer {
  overflow: visible !important;
}

.inventoryPanel {
  max-height: 100vh;
  overflow: hidden;
  border-radius: 4px;
}

/*::-webkit-scrollbar {
  width: 0px;  !* remove scrollbar space *!
  background: transparent;  !* optional: just make scrollbar invisible *!
}
!* optional: show position indicator in red *!
::-webkit-scrollbar-thumb {
  background: #FF0000;
}*/

.inventoryPanel .mat-dialog-container {
  overflow: visible !important;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.table-height{
  height:calc(100vh - 471px) !important;
}

.table-height-campaign {
  height:calc(100vh - 801px) !important;
}
/*table common css*/
table.table tbody {
  display: block;
  min-height: calc(100vh - 198px);
  border-bottom: 1px solid #E5E5E5;
}

table.table thead{
  display: table;
  width: 100%;
  table-layout: fixed;
  border-top: 1px solid #E5E5E5;
  cursor: default;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999999;
}
table.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-top: 1px solid #E5E5E5;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
}
table.table {

  border-collapse: inherit;
  border-spacing: 0;
  /*-webkit-transform: rotateX(180deg) ;*/
  /*transform: rotateX(180deg) ;    */
  margin-bottom: 0;
}
.table-responsive{
  overflow:scroll;
}
.table thead {
  padding: 13px 6px;
  color: #737376;
  background-color: #F5FAFE;
  border: none;
}

.table > tbody > tr > td,
.table > thead > tr > th {
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.table > tbody > tr > td {
  /*padding: 13px 8px;*/
  font-family: Stolzl-Regular;
  font-size: 14px;
  color: #121744;
  padding: 15px 13px;
  font-weight: normal;

}

.table tr th:first-child {
  padding-left: 20px;
}
.table tbody tr td:first-child {
  padding-left: 27px;
}
.table tr th:last-child{
  text-align: left;
  padding-right: 20px;
}

/*table loader*/

tr.loader .table-loader {
  width: 62px;
  height: 62px;
  margin: 0 30%;
}
/*edit campaign loader*/

.loader-eidt-campaign {
  width: 62px;
  height: 62px;
  margin: 0 30%;
  top: -130px;
}

.loader-container {
  width: max-content;
  right: 50%;
  top: 45%;
  position: absolute;
}

.fa-li {
  position: relative;
}

/*table pagination*/

.btn-default {
  font-family: Stolzl-Regular;
  font-weight: normal;
  font-size: 13px;
}

.table tfoot tr.bottom-row {
  width: 100%;
  background-color: #FFF;
   border-top: solid 1px #E5E5E5;
  /* border-radius: 0 0 6px 6px; */
  display: inline-table;
}

.table tfoot tr.bottom-row td {
  padding: 17px 27px;
}

.table tfoot td.bottom-row-left {
  color: #121744 !important;
  font-size: 13px;
  font-family: Stolzl-Regular;
  font-weight: normal;
  line-height: 2;
  text-align: left;
  padding-left: 20px;
}

.table tfoot td.bottom-row-right {
  float: right;
}

/*table pagination*/

@media screen and (max-width: 1244px) and (min-width: 993px) {
  table.table, .btn-default, .table tfoot td.bottom-row-left {
    font-size: 7px;
  }
}

@media screen and (max-width: 994px) and (min-width: 892px) {
  table.table, .btn-default, .table tfoot td.bottom-row-left {
    font-size: 9px;
  }

  .table tfoot tr.bottom-row {
    bottom: 0;
  }
}



.pointer {
  cursor: pointer;
}

.blue-text {
  font-size: 12px;
  color: #69BAC3;
  line-height: 3;
}

.roles_table, .roles_row {
  margin-top: 20px !important;
}

.dialog-btn {
  background-color: #7BB16D;
  color: #FFFFFF;
  box-shadow: none;
  border: none;
  font-family: 'Montserrat', sans-serif;
  padding: 5px;
  border-radius: 6px;
  font-size: 14px;
  width: 88px;
  height: 34px;

}

.app-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 23% 41%;
  z-index: 9;
  background-color: transparent;
}

.heading {
  cursor: pointer;
}

.heading:focus {
  outline: none;
}

.dropdown-menu {
  line-height: 2;
  z-index: 99999999;
}

.amountInput {
  text-indent: 7px;
  background-image: url(assets/images/dollar.svg);
  background-position: 4px 49%;
  background-repeat: no-repeat;
  background-size: 15px;
}

.table-striped tbody tr:focus, .table-striped tbody tr td:focus, .table tbody tr td:focus, .table tbody tr:focus {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

.payment-header-left li a {
  text-decoration: none;
}

.header-left a {
  text-decoration: none;
}

.table1 {
  padding: 2% !important;
}

.pos-realtive {
  position: relative;
}

.calander-input-icon {
  background-image: url(./assets/images/Group_398.svg);
  background-repeat: no-repeat;
  background-position: calc(6% - 5px) 50%;
  background-size: 13px;
  padding-left: 26px;
  min-width: 144px;
}

.no-data, .data {
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.error_sign {
  font-size: 16px;
  color: #d43936;
  margin-left: -2.5%;
  margin-right: 1%;
  margin-top: 1%;
  display: none;
}

/* table sorting css */
.table tr th {
  vertical-align: middle;
  position: relative;
}



.table tr th a {
  cursor: pointer;
}


/* page limit drop-down css */
.page-limit {
  border: none;
  background-color: white;
  background-position: calc(137% - 2.3em) 50%;
  cursor: pointer;
  min-width: 46px;
}


.page-limit:focus {
  outline: none;
}
.button[disabled] {
  opacity: .6;
}

/* page limit drop-down css */

@-moz-document url-prefix() {
  select.form-control {
    padding-right: 25px;
    background-image: url("data:image/svg+xml,\
      <svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='10px'\
           height='10px' viewBox='0 0 1200 1000' fill='rgb(85,85,85,1)'>\
        <path d='M1100 411l-198 -199l-353 353l-353 -353l-197 199l551 551z'/>\
      </svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 7px) 50%;
    -moz-appearance: none;
    appearance: none;
  }
}

span.paginate {
  position: absolute;
  margin-top: 1.8%;
  font-size: 13px;
  font-family: 'Stolzl-Regular';
  color: #121744;
}



.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover {
  background-color: #ddd;
  border-color: #ccc;
}

.btn-default:disabled {
  background: #f8f9fa;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-sm {
  padding: 8px 10px;
  font-size: 12px;
  line-height: 1.5;
  /*border-radius: 3px;*/
  border: 2px solid #EDEDED;
  border-radius: 20px;
  background-color: #FFF;
}

.btn-round {
  border-radius: 50%;
}

input {
  box-shadow: none !important;
}

button {
  cursor: pointer;
  box-shadow: none;
}

button:disabled {
  cursor: not-allowed;
}

input:disabled {
  cursor: not-allowed;
}

select:disabled {
  cursor: not-allowed;
}

.btn:not(:disabled):not(.disabled) {
  box-shadow: none !important;
}

select {
  box-shadow: none !important;
}

.table tr th {
  font-family: Stolzl-Regular;
  font-size: 14px;
  color: #737376;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: normal;
  text-transform: uppercase;

}

.theme-green .bs-datepicker-head {
  background-color: #E5E5E5;
  font-family: Stolzl-Regular;

}
.bs-datepicker-body table th{
  color: #737376;
  font-family: Stolzl-Medium;
  font-weight: unset;
  padding: 13px 10px;}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #419DF1;
  color: #FFF;
  font-size: 11px;
}
.bs-datepicker-body table td{ color: #737376; font-size: 10px; font-family: Stolzl-Book;}

.theme-green .bs-datepicker-body table td.week span {
  color: #02bcda;
  font-family: ubuntu-regular;
}

[type="submit"] {
  border: none;
  -webkit-appearance: button-bevel;
}

.headings {
  font-family: Stolzl-Bold;
  font-size: 45px;
  color: #121744;
}
.add-button{
  background-color: #429DF1;
  color: #FFF;
  padding: 11px 15.67px;
  font-size: 14px;
  font-family: Stolzl-Book;
  border-radius: 8px;    cursor: pointer;}

.cancelButton{
  background-color: #E0E0E0;
  box-shadow: none;
  border: none;
  color: #737376;
  height: 39px;
  padding: 2px 15px;
  font-size: 14px;
  border-radius: 6px;
  font-family: 'Stolzl-Book';
}
.form-check-input:checked {
  background-image: url('./assets/images/Group_484.svg');
  background-position: center;
  }
.form-check-input{
    cursor: pointer;
    -webkit-appearance: initial;
    appearance: initial;
    border: none;
    background: #e0e0e0;
    height: 17px;
    width: 17px;
    border-radius: 18px;
    top: 7px;
}
.mat-slide-toggle-content{
  font-family: Stolzl-Regular;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar{
  background-color: #419df185 !important ;
}
.mat-slide-toggle-bar{
  background-color: rgb(0 0 0 / 21%) !important;
}
.mat-slide-toggle-thumb{
  background-color: #419DF1 !important;
}


.filter-button{        padding: 9px 15.67px;
  font-size: 14px !important;
  border-radius: 8px;
  background-color: #E0E0E0;
  color: #737376;
  font-family: 'Stolzl-Book';
  border: none;}
.export_btn{      background-color: #429DF1;
  color: #FFF;
  padding: 9px 26.344px;
  font-size: 14px;
  font-family: Stolzl-Book;
  border-radius: 8px;
  border: none;}
.table_content{    background-color: #FFF;
  border-radius: 6px;
  border: 2px solid #EDEDED;
  }
.search-bar {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  font-family: Stolzl-Regular;
  font-size: 15px;
}
.search-bar::placeholder{
  color: #CCCCCC;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.table-button {
  background-color: #429DF1;
  color: #fff;
  padding: 2px 20px;
  font-size: 12px;
  border-radius: 8px;
  width: 80px;
  border: none;
  font-family: Stolzl-Book;
}
.table td, .table th{ border: none}

/** Details Page Css Start Here Only -- Lakshmanan 24/02/2021*/

.b_shadow{
  margin-top: 11px;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  margin-bottom: 12px;
  background-color: #FFFFFF;
}
.heading-card{
  padding: 24px 0px !important;
  color: #737376;
  background-color: #F5FAFE;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: none !important;
}
.left-heading{
  font-size: 14px;
  color: #737376;
  text-transform: uppercase;
  font-family: Stolzl-Regular;
}
.detail-btn {
  background-color: #429DF1;
  color: #FFF;
  padding: 11px 9px;
  font-size: 14px;
  cursor: pointer;
  font-family: Stolzl-Book;
  border-radius: 5px;
  border: none;
}
.table-data{
  font-size: 15px;
  line-height: 2.5;
  color: #121744;
  font-family: Stolzl-Regular;
}
span.detail_desc {
  width: 50%;
  text-align: left;
  color: #121744;
  font-family: Stolzl-Regular;
}
.header-right{
  background-color: #429DF1;
  color: #FFF;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  font-family: Stolzl-Book;
  border-radius: 8px;
}
right-heading{
  font-weight: normal;
  width: 100%;
  text-align: right;
  cursor: pointer;
}
.head-email{
  font-weight: normal;
  font-size: 28px;
  font-family: Stolzl-Bold;
  color: #121744;
}
.head-text{
  font-size: 24px;
  padding: 5px 0 0 0;
  color: #121744;
  font-family: Stolzl-Regular;
}
.customer_id{
  background-color: #E0E0E0;
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  font-size: 15px;
  color: #121744;
  font-family: Stolzl-Regular;
  vertical-align: text-bottom;
}

.inner-details-subsc .left-heading {
  font-weight: normal;
  color: #121744;
}

.inner-details-subsc .right-data {
  font-weight: normal;
  color: #121744;
}

.inner-details-subsc .left-heading,
.inner-details-subsc .right-data {
  font-size: 15px;
  line-height: 2.5;
  font-family: Stolzl-Regular;
}
.detail_desc {
  float: left;
  width: 26%;
  font-size: 15px;
  color: #121744;
  font-family: Stolzl-Regular;

}
.details_info {
  float: left;
  width: auto;
  color: #121744;
  font-size: 15px;
  font-family: Stolzl-Regular;
}
.bold {
  color: #121744;
  font-size: 15px;
  font-family: Stolzl-Regular;
}
.table_content-detail {
  background-color: #FFF;
  border-radius: 6px;
}
.table_content-detail .thead {
  background-color: inherit;
}

/** message dialog css start here */
.heading {
  color: #121744;
  font-size: 15px;
  font-family: Stolzl-Regular;
  padding: 14px 0 14px 16px;
}
.table{min-width: 1140px;}
.mat-drawer-container{background: #F5F5F5 0% 0% no-repeat padding-box;}
.card-heading{ background-color: #F5FAFE; font-size: 15px; font-family: Stolzl-Regular; text-transform: uppercase; color: #737376;  border-top-left-radius: 6px; border-top-right-radius: 6px;

  width: 100%;}
.card-content{    padding: 30px 63px 30px 63px;}


/** create component css start here*/
.update_cancel {
  padding: 6px 15px;
  margin-right: 20px;
  font-size: 14px;
  color: #737376;
  font-family: Stolzl-Book;
  border-radius: 5px;
  border: none;
}
.bg-infos {
  background-color: var(--unnamed-color-419df1) 0% 0% no-repeat padding-box;
  background-color: #F5FAFE;
}

.main {
    background-color: #ffffff;
}
label, .form-control {
    font-size: 14px;
    font-family: Stolzl-Regular;
    color: #121744;
}
.opt-cnt {
    font-family: Stolzl-Regular;
    font-size: 11px;
    padding: 4px;
    background: #F2F3F3 0% 0% no-repeat padding-box;

}
.head{
  font-family: Stolzl-Medium;
  color: #121744;
  font-size: 17px;
  opacity: 1;
}
.create-header {
    padding: 22px 30px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.header {
  font-family: Stolzl-Medium;
  color: #121744;
  font-size: 20px;
  opacity: 1;
}
.cancel_btn {
  padding: 6px 15px;
  margin-right: 20px;
  color: #737376;
  font-family: Stolzl-Book;
  float: left;
  font-size: 14px;
  height: 39px;
  border-radius: 5px;
  border: none;
  background-color: rgb(239,239,239);
}
.update-btn {
    background-color: #429DF1;
    color: #FFF;
    padding: 2px 30px;
    height: 39px;
    font-size: 14px;
    cursor: pointer;
    font-family: Stolzl-Book;
    border-radius: 5px;
    border: none;
}
span.input-group-addon.last-d {
  padding-top: 3.2%;
  background: #ededed;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: Stolzl-Regular;
  color: #676b70;
}
.phn-err-msg {
  margin-top: 39px;
}
.alert-heading {
  font-size: 18px;
  color: #121744;
  font-family: Stolzl-Regular;
  font-weight: normal;
  padding: 14px 0 14px 16px;
}
.delete_cust {
  padding: 11px;
  margin: 20px 15px 0px 15px;
  color: #121744;
  font-family: Stolzl-Regular;
  font-size: 15px;
  text-align: center;
}
.detail-content {
  padding: 1.5rem;
  background-color: #FFFFFF;
  border-bottom-left-radius: 6px; border-bottom-right-radius: 6px
}
.no-data-avilable {
  font-size: 15px;
  font-family: Stolzl-Regular;
  color: #121744;
}
.level-text {
  padding: 9px 25px;
  text-align: center;
  vertical-align: baseline;;
  color: #777779;
  cursor: pointer;
  background-color: #E0E0E0;
  font-size: 14px;
  font-family: Stolzl-Book;
  border-radius: 5px;
  max-width: 118px;
}

.level-text-active {
  border-radius: 5px;
  background-color: #429DF1;
  border-bottom: 0;
  color: #ffffff;
}
.create-card-img {
  position: absolute;
  right: 8%;
  top: 9px;
}

.page-link{

  color: #121744 !important;
  text-align: center;
  font-size: 13px;
  font-family: 'Stolzl-Regular';
  padding: 4px 10px;
  border:none;
  margin-top: 7px;
  margin-right: 2px;
}
.paginate_active{       background-color: #419DF1 !important;
  color: #FFF !important;
  text-align: center;
  font-size: 12px;
  font-family: 'Stolzl-Medium';
  padding: 4px 10px;border:none;}

.btn_prenext{font-size: 14px; font-family: Stolzl-Regular;border: none; color: #419DF1;}
.btn_prenext:disabled{  color: #CACACA; background-color: #FFF }
.btn_prenext:hover { color: #419DF1; border:none; background-color: #FFF;}
.fa-angle-double-right, .fa-angle-double-left{    font-weight: bold;
  font-size: 13px;}

.wrapper{
  -webkit-transform: translate3d(0,0,0) !important;
}
.selected-item {
  max-width: 500px !important;
  border: 1px solid #000 !important;
  background-color: #FFF !important;
  color: #121744 !important;
}
.multiselect-dropdown {
  font-size: 15px !important;
  font-family: Stolzl-Regular !important;
  color: #121744 !important;
}
.multiselect-dropdown .dropdown-btn span:last-child {
 padding-right: 15px !important;
}
.dropdown-multiselect__caret  {
  height: 39px !important;
  width: 35px !important;
}
.multiselect-dropdown .dropdown-btn span a {
  background-color: #FFFFFF !important;
  color: #121744 !important;
  padding-top: 1px !important;
}
.multiselect-item-checkbox input[type=checkbox]:checked+div:before  {
  background-color: #429DF1 !important;
  border: 2px solid #429DF1 !important;
}
multiselect-item-checkbox.multiselect-item-checkbox input[type=checkbox]::before input[type=checkbox]+ div:before {
  color: #429DF1 !important;
  border: 2px solid #429DF1 !important;
}
 .mat-form-field-label{
  color: #121744 !important;
  font-family:Stolzl-Regular !important;
}
.mat-select-placeholder{
  display: none;
}
/* safari  */
select{
  appearance: none;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E);
    background-size: 0.6em;
    background-position: calc(100% - 1.3em) center;
    background-repeat: no-repeat;
}
.mat-option-text , mat-select-trigger{
  font-family: Stolzl-Regular;
}
.mat-button-toggle-label-content{
  line-height: 30px !important;
}
table thead tr th span{ cursor: pointer;}

.search-mark {
  padding: 0;
  background-color: yellow;
}
select{
  text-overflow: ellipsis;
  padding-right: 31px !important;
}
.mt-3-6{
margin-top: 3.6rem;
}
.col-form-label {
  font-size: 14px;
  color: #121744 !important;
  font-family: Stolzl-Regular;
}
.mat-input-infix {
  font-size: 14px !important;
}
.message-info {
  font-size: 14px;
  color: #737376;
  font-family: 'Stolzl-Regular';
}
.body-contain{
  min-width: 375px;
}
.page-container{
  overflow: scroll !important;
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .table-height {
    height: calc(100vh - 300px) !important;
  }
}
@media (min-width: 1024px) and (max-width: 1365.98px){
  .table-height {
    height: calc(100vh - 100px) !important;
  }
}
@media (max-width: 575.98px) {
  .page-container {
    overflow-x: hidden !important;
  }
  .multiselect-dropdown {
    font-size: 13px !important;

  }
  .add-button{    position: absolute;
    left: 12px;    top: -3px;}
  .page-container {
    padding: 0px;
  }
  .right_content_block , .mat-toolbar{
    background-color: #ebebeb !important;
  }
  .bs-datepicker{
    transform: translateX(0.5%);
  }
  bs-datepicker-container{
    transform: translate(-11%,92%) !important;
  }
  .b_shadow{
    margin-left: 11px;
    margin-right: 15px;
  }
  .cancel_btn{
    padding: 6px 30px;
  }
  .head-email{
   font-size: 21px;
  }
  .customer_id{
    font-size: 13px  !important;
  }
  .head-text{
    font-size: 17px;
  }
  .headings{
    font-size: 24px;
    text-align: center;
    margin-top: 4px;
  }
  .calander-input-icon {

    background-position: calc(6% - 0px) 50%;
    background-size: 13px;
    padding-left: 24px;
    min-width: 110px;
    padding-right: 0;
  }
  .px-m-0{
    padding-left: 0px;
    padding-right: 0px;
  }
  .border-2{
    border-top: 2px solid #eee;
  }
  .list-inline-item:not(:last-child){
    margin-right: 0.1rem;
  }
  .page-link{
    padding: 4px 4px;
  }
}
@media screen and (max-width : 360px) {
  .body-contain{
    transform: translateX(-2.1%);
    min-width: 335px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {}
@media (min-width: 768px) and (max-width: 991.98px)  {
  .px-md-m-0{
    padding-left: 0px;
    padding-right: 0px;
  }
  .list-inline-item:not(:last-child){
    margin-right: 0.1rem;
  }
  .page-link{
    padding: 4px 9px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px){

}
@media (min-width: 1200px) {  }
@media (orientation: landscape) and (hover: none) and (pointer: coarse) and (min-width: 576px) and (max-width: 767.98px) {
  /* Styles for Landscape screen */
.table-height{ height: auto !important;}
}
/* width */
.table-height::-webkit-scrollbar, .table-responsive::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}


/* Handle */
.table-height::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
  background: #419DF1;
  border-radius: 10px;

}
.table-height::-webkit-scrollbar-thumb:horizontal, .table-responsive::-webkit-scrollbar-thumb:horizontal {
  background: #FFF;
  border-radius: 10px;

}
/* Handle on hover */
.table-height::-webkit-scrollbar-thumb:hover, .table-responsive::-webkit-scrollbar-thumb:hover{
  background: #419DF1;
}
.top-scroll{overflow:scroll; height: 15px;overflow-y: hidden; }
.top-scroll::-webkit-scrollbar, .table-responsive::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}
.top-scroll::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
  background: #419DF1;
  border-radius: 10px;
}
.top-scroll::-webkit-scrollbar-thumb:hover, .table-responsive::-webkit-scrollbar-thumb:hover{
  background: #419DF1;
}

.success-snack  .mat-simple-snackbar-action {
  color: #7BB16D !important;
}
